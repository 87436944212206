import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "payment-bank-data-section" }
const _hoisted_2 = { class: "title is-size-5" }
const _hoisted_3 = {
  for: "iban",
  class: "form-field-label"
}
const _hoisted_4 = { class: "form-field form-field-text" }
const _hoisted_5 = { class: "control text-form-input" }
const _hoisted_6 = { id: "bank-name-info" }
const _hoisted_7 = { class: "help" }
const _hoisted_8 = { id: "bank-name-legacy" }
const _hoisted_9 = { id: "bank-name-iban" }
const _hoisted_10 = {
  key: 0,
  id: "bank-name-not-available",
  class: "help"
}
const _hoisted_11 = {
  key: 1,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollTarget = _resolveComponent("ScrollTarget")!
  const _component_AccountNumberField = _resolveComponent("AccountNumberField")!
  const _component_TextField = _resolveComponent("TextField")!

  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    _createVNode(_component_ScrollTarget, { "target-id": "payment-form-iban-scroll-target" }),
    _createElementVNode("legend", _hoisted_2, _toDisplayString(_ctx.$t( 'donation_form_payment_bankdata_title' )), 1),
    _createElementVNode("div", {
      class: _normalizeClass(['form-input', { 'is-invalid': _ctx.bankDataIsInvalid }])
    }, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t( _ctx.labels.iban )), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AccountNumberField, {
            id: 'iban',
            placeholder: _ctx.$t( 'donation_form_payment_bankdata_account_iban_placeholder' ),
            "account-id": _ctx.accountId,
            "data-track-content": _ctx.getTrackingCode !== '',
            "data-content-piece": _ctx.getTrackingCode,
            onValidate: _ctx.validate,
            onInput: _ctx.setAccountId
          }, null, 8, ["placeholder", "account-id", "data-track-content", "data-content-piece", "onValidate", "onInput"])
        ])
      ])
    ], 2),
    _withDirectives(_createVNode(_component_TextField, {
      label: _ctx.$t( _ctx.labels.bic ),
      placeholder: _ctx.labels.bicPlaceholder != '' ? _ctx.$t( _ctx.labels.bicPlaceholder ) : '',
      modelValue: _ctx.bankIdentifier,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bankIdentifier) = $event)),
      name: "bic",
      "input-id": "bic",
      "show-error": _ctx.bankDataIsInvalid,
      "error-message": '',
      onFieldChanged: _ctx.validate
    }, null, 8, ["label", "placeholder", "modelValue", "show-error", "onFieldChanged"]), [
      [_vShow, _ctx.isBankFieldEnabled]
    ]),
    _createElementVNode("div", _hoisted_6, [
      _withDirectives(_createElementVNode("span", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.getBankName), 1),
          _withDirectives(_createElementVNode("span", null, " (" + _toDisplayString(_ctx.bankIdentifier) + ")", 513), [
            [_vShow, _ctx.showBankId]
          ])
        ])
      ], 512), [
        [_vShow, _ctx.bankInfoValidated]
      ]),
      (_ctx.bankInfoValidatedButInfoMissing)
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t( 'donation_form_payment_bankdata_bank_bic_placeholder_full' )), 1))
        : _createCommentVNode("", true),
      (_ctx.bankDataIsInvalid)
        ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t( 'donation_form_payment_bankdata_error' )), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}